.hoox-product-info {
	padding: 48px 0;
	background-color: #FAFAFA;
}
.hoox-product-info__title {
	margin-bottom: 8px;
}

.hoox-product-info__subtitle {
	margin-bottom: 32px;
}

.hoox-product-info__bullet-icon {}

.hoox-product-info__tabs {
	position: relative;
	margin-bottom: 32px;
}
.hoox-product-info__tab {
	position: relative;
}

.hoox-product-info__tab span {
	text-align: left;
}
.hoox-product-info__tab.active {
	font-weight: bold;
}
.hoox-product-info__tab--selected {}
.hoox-product-info__tabs-selected-background {
	display: none;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.hoox-product-info__panels {
	position: relative;
	overflow: hidden;
}
.hoox-product-info__panel {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	top: 0;
	left: 0;
	transition: .3s;
}
.hoox-product-info__panel.active {
	position: relative;
	opacity: 1;
	visibility: visible;
}
.hoox-product-info__panel-info {}

.hoox-product-info__panel-info h5 {
    color: var(--hoox-color-slate-secondary);
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    font-family: 'Monotype Century Old Style';
    margin-bottom: 12px;
}

.hoox-product-info__panel-info ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
}

.hoox-product-info__panel-info ul li {
	color: var(--hoox-color-slate-secondary);
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
}

.hoox-product-info__panel-info p {
	color: var(--hoox-color-slate-secondary);
	font-size: 15px;
	font-weight: 400;
	line-height: 150%;
	margin-bottom: 32px;
}

.hoox-product-info__panel-info {}
.hoox-product-info__panel-media {}
.hoox-product-info__panel-image {}
.hoox-product-info__bullet-icon.hidden {
	display: none;
}

@media (max-width: 768px) {
	.hoox-product-info__tabs.active .hoox-product-info__tabs-list {
		visibility: visible;
		opacity: 1;
	}
	.hoox-product-info__tabs.active .hoox-product-info__tabs-selected-background {
		display: block;
	}
	.hoox-product-info__tabs.active .hoox-product-info__tab--selected svg {
		transform: rotate(180deg);
	}
	.hoox-product-info__tabs-list {
		position: absolute;
		top: 100%;
		background-color: var(--hoox-color-white);
		z-index: 2;
		visibility: hidden;
		opacity: 0;
		transition: .3s;
		width: 100%;
	}
	.hoox-product-info__tabs-list .hoox-product-info__tab {
		margin-top: -1px;
	}
	.hoox-product-info__tab {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 40px;
		width: 100%;
		border: 1px solid;
		padding: 5px 15px;
	}
	.hoox-product-info__tab span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 90%;
	}
	.hoox-product-info__tab--selected svg {
		transition: .3s;
	}
	
}

@media (min-width: 1024px) {
	.hoox-product-info__tabs {
		display: flex;
		max-width: 700px;
		margin: auto;
		margin-bottom: 48px;
	}
	.hoox-product-info__tab--selected {
		display: none;
	}

	.hoox-product-info {
		padding: 64px 0 80px;
	}

	.hoox-product-info__subtitle {
		margin-bottom: 48px;
	}

	.hoox-product-info__tab {
		padding: 8px 36px;
		height: 60px;
		max-width: 300px;
	}

	.hoox-product-info__tabs-list .hoox-product-info__tab:nth-child(2) {
		min-width: 200px;
		padding: 8px 0;
	}

	.hoox-product-info__tabs-list {
		display: flex;
	}

	.hoox-product-info__tab.active::after {
		position: absolute;
		content: '';
		left: 0;
		bottom: 0;
		border-bottom: 2px solid var(--hoox-color-slate-secondary);
		width: 100%;
	}

	.hoox-product-info__panels {
		max-width: 1023px;
		margin: auto;
	}

	.hoox-product-info__panel {
		display: flex;
		gap: 80px;
	}

	.hoox-product-info__panel-info h5 {
		margin-bottom: 16px;
	}

	.hoox-product-info__panel-info ul li {
		gap: 8px;
	}

	.hoox-product-info__panel-info p {
		font-size: 16px;
		margin-bottom: unset;
	}

	.hoox-product-info__panel-media {
		width: 383px;
		max-height: 345px;
		flex-shrink: 0;
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait){
	.hoox-product-info__tabs.active .hoox-product-info__tabs-list {
		visibility: visible;
		opacity: 1;
	}
	.hoox-product-info__tabs.active .hoox-product-info__tabs-selected-background {
		display: block;
	}
	.hoox-product-info__tabs.active .hoox-product-info__tab--selected svg {
		transform: rotate(180deg);
	}
	.hoox-product-info__tabs-list {
		position: absolute;
		top: 100%;
		background-color: var(--hoox-color-white);
		z-index: 2;
		visibility: hidden;
		opacity: 0;
		transition: .3s;
		width: 100%;
	}
	.hoox-product-info__tabs-list .hoox-product-info__tab {
		margin-top: -1px;
	}
	.hoox-product-info__tab {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 40px;
		width: 100%;
		border: 1px solid;
		padding: 5px 15px;
	}
	.hoox-product-info__tab span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 90%;
	}
	.hoox-product-info__tab--selected svg {
		transition: .3s;
	}
	
}